import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import InputDate from '../components/ui/InputDate';
import Input from '../components/ui/Input';
import InputGroup from '../components/ui/InputGroup';
import InputGroupRemovable from '../components/ui/InputGroupRemovable';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import UniversalInputGenerator from '../interfaces/UniversalInputGenerator';
import {
  getBlockRatesUsage,
  getEndDate,
  getStartDate,
  setBlockRatesUsage,
  setEndDate,
  setPeakOffPeakUsage,
  setSingleRateUsage,
  setStartDate,
} from '../utils/localStorage/localStorageFunctions';
import { BlockRatesUsageLsType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const UsageSchema = Yup.number()
  .typeError('Usage must be a number')
  .min(0.01, 'Usage must be more than 0')
  .max(10_000, 'Please check the usage amount');

const BlockRatesTariffSchema = Yup.object().shape(
  {
    startDate: Yup.date()
      .typeError('Please, specify the start date')
      .when('endDate', (endDate: any, schema: any) => {
        return (
          endDate && schema.max(endDate, 'Start date needs to before end date')
        );
      })
      .required('Please, specify the start date'),
    endDate: Yup.date()
      .typeError('Please, specify the end date')
      .when(
        'startDate',
        (startDate: any, schema: any) =>
          startDate &&
          schema.min(startDate, 'End date needs to be after start date')
      )
      .required('Please, specify the end date'),
    blockUsage1: UsageSchema.required('Please, specify the usage amount'),
    blockUsage2: UsageSchema.required('Please, specify the usage amount'),
    blockUsage3: UsageSchema,
    blockUsage4: UsageSchema,
  },
  [['startDate', 'endDate']]
);

const BlockRatesTariff = () => {
  const inputsLimit = 4;

  const [inputs, setInputs] = useState<UniversalInputGenerator[]>(() => {
    const inputsArray = [
      {
        label: 'Block 1 usage',
        id: 'input-block-usage-1',
        name: 'blockUsage1',
        index: 1,
        removable: false,
      },
      {
        label: 'Block 2 usage',
        id: 'input-block-usage-2',
        name: 'blockUsage2',
        index: 2,
        removable: false,
      },
    ];

    const { blockUsage3, blockUsage4 } = getBlockRatesUsage() || {};

    if (blockUsage3) {
      inputsArray.push({
        label: 'Block 3 usage',
        id: 'input-block-usage-3',
        name: 'blockUsage3',
        index: 3,
        removable: true,
      });
    }
    if (blockUsage4) {
      inputsArray.push({
        label: 'Block 4 usage',
        id: 'input-block-usage-4',
        name: 'blockUsage4',
        index: 4,
        removable: true,
      });
    }

    return inputsArray;
  });

  const addBlockUsageInput = () => {
    setInputs((prevState) => {
      const lastElement = prevState[prevState.length - 1];
      const newElementIndex = lastElement.index + 1;

      const newInput = {
        label: `Block ${newElementIndex} usage`,
        id: `input-block-usage-${newElementIndex}`,
        name: `blockUsage${newElementIndex}`,
        index: newElementIndex,
        removable: true,
      };

      return [...prevState, newInput];
    });
  };

  const removeInputByName = (inputName: string) => {
    setInputs((prevState) =>
      prevState.filter((input) => input.name !== inputName)
    );
  };

  const isBelowInputLimit = inputs[inputs.length - 1].index < inputsLimit;

  const [initialFormValues] = useState(() => {
    const startDateLS = getStartDate();
    const endDateLS = getEndDate();
    const { blockUsage1, blockUsage2, blockUsage3, blockUsage4 } =
      getBlockRatesUsage() || {};

    return {
      startDate: startDateLS || '',
      endDate: endDateLS || '',
      blockUsage1: blockUsage1?.toString() || '',
      blockUsage2: blockUsage2?.toString() || '',
      blockUsage3: blockUsage3?.toString() || '',
      blockUsage4: blockUsage4?.toString() || '',
    };
  });

  const handleNext = (values: typeof initialFormValues) => {
    const {
      blockUsage1,
      blockUsage2,
      blockUsage3,
      blockUsage4,
      endDate,
      startDate,
    } = values;

    setStartDate(startDate);
    setEndDate(endDate);

    const blockRatesUsage: BlockRatesUsageLsType = {
      blockUsage1: parseInt(blockUsage1, 10),
      blockUsage2: parseInt(blockUsage2, 10),
    };

    if (blockUsage3) {
      blockRatesUsage.blockUsage3 = parseInt(blockUsage3, 10);
    }
    if (blockUsage4) {
      blockRatesUsage.blockUsage4 = parseInt(blockUsage4, 10);
    }

    setBlockRatesUsage(blockRatesUsage);
    setPeakOffPeakUsage(null);
    setSingleRateUsage(null);

    navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
  };

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_ABOUT_YOUR_BILL);
  };

  usePreloadPageData(StepUrl.URL_CONTROLLED_LOAD, StepUrl.URL_ABOUT_YOUR_BILL);

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={6}
        pageUrl={StepUrl.URL_BLOCK_RATES_TARIFF}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={BlockRatesTariffSchema}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Block rates tariff</h2>
                <p className="sub-heading">
                  This information can be found on your bill.
                </p>
                <InputGroup className="my-2">
                  <Label htmlFor="input-start-date">Start date</Label>
                  <InputDate id="input-start-date" name="startDate" />
                </InputGroup>
                <InputGroup className="my-2">
                  <Label htmlFor="input-end-date">End date</Label>
                  <InputDate id="input-end-date" name="endDate" />
                </InputGroup>
                {inputs.map((input, index) => (
                  <InputGroupRemovable
                    isRemovable={
                      index + 1 === inputs.length && !!input?.removable
                    }
                    className="my-2"
                    key={input.name}
                    label={<Label htmlFor={input.id}>{input.label}</Label>}
                    input={
                      <Input
                        id={input.id}
                        name={input.name}
                        type="number"
                        unit="kWh"
                        controls
                        min={0.01}
                        step={0.01}
                      />
                    }
                    onRemove={() => {
                      removeInputByName(input.name);
                      props.setFieldValue(input.name, '');
                    }}
                  />
                ))}
                {isBelowInputLimit && (
                  <Button
                    className="my-2"
                    type="button"
                    variant="link"
                    onClick={() => {
                      addBlockUsageInput();
                    }}
                  >
                    Add additional block usage
                  </Button>
                )}
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={
                    props.values.startDate === '' ||
                    props.values.endDate === '' ||
                    props.values.blockUsage1 === '' ||
                    props.values.blockUsage2 === ''
                  }
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default BlockRatesTariff;
